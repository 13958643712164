import React from "react";
import Icofont from "../../components/IcoFont";
import CartDropdownItem from "../../components/cart/CartDropdownItem";
import _ from "lodash";
import moment from "moment";
import { usePriceFormat } from "../../hooks";
import { useTrans } from "../../hooks";

const OrderDetails = ({ order }) => {
  const { priceFormat } = usePriceFormat();
  const transMsg = useTrans();

  return (
    <>
      <div className="row">
      {!_.isEmpty(order.deliver?.customerTrackURL) && <iframe src={order.deliver?.customerTrackURL} width="100%" height="1700px" scrolling="yes" style={{marginBottom:"30px"}}/>}

        <div className="col-lg-4">
          <div className="row pt-2 pb-3">
            <div className="col-12">
              <h5 className="">
                {transMsg("orderDetails")}- {order.displayOrderId}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pb-1">
              <small>{_.upperCase(transMsg("from"))}:</small>
              <h6 className=" mt-1">
                <Icofont icon="food-cart" /> {_.capitalize(order.location.name)}{" "}
              </h6>
              <p>
                {_.capitalize(order.location.address.address_1)}, {_.capitalize(order.location.address.city)}, {_.capitalize(order.location.address.postalCode)}
              </p>
              <hr />
            </div>
            <div className="col-12 py-1">
              <small>{_.upperCase(transMsg("scheduleTime"))}:</small>

              <div className=" mt-1">
                <Icofont icon="clock-time" /> {order.scheduleTime.display}
              </div>
              <hr />
            </div>
            <div className="col-12 py-1">
              <small>{_.upperCase(transMsg("method"))}:</small>
              <div className="text-grey  mt-1">{_.capitalize(transMsg(order.type))}</div>
              {order.type === "delivery" && (
                <div className=" py-1">
                  <small>{_.upperCase(transMsg("to"))}:</small>
                  <div className="text-grey  text-capitalize">
                    {order.shippingAddress && (
                      <>
                        {`${`${order.shippingAddress.address_1}`} -
                        ${order.shippingAddress.address_2 || ""},
                    ${order.shippingAddress.city}, 
                    ${order.shippingAddress.province}, 
                    ${order.shippingAddress.postalCode}, 
                    ${order.shippingAddress.country}`}
                      </>
                    )}
                  </div>
                </div>
              )}
              <hr />
            </div>

            <div className="col-12 py-1">
              <small>{_.upperCase(transMsg("customer"))}:</small>
              <div className=" mt-1">
                <Icofont icon="email" /> {order.email}{" "}
              </div>
              <div className=" mt-1">
                <Icofont icon="phone" /> {order.shippingAddress?.phone || order.billingAddress?.phone}{" "}
              </div>
              <hr />
            </div>
            <div className="col-12 py-1">
              <small>{_.upperCase(transMsg("orderReceived"))}:</small>
              <h6 className="text-grey  mt-1">{moment(order.created_at).format("MMMM Do YYYY, h:mm A")}</h6>
              <hr />
            </div>

            {!_.isEmpty(order.note) && (
              <div className="col-12 py-1">
                <small>{_.upperCase(transMsg("note"))}:</small>
                <h6 className="text-grey  mt-1">{_.capitalize(order.note)}</h6>
                <hr />
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-8">
          <hr className="d-block d-lg-none" />
          <div className="row">
            <div className="col-12">
              <small>
                <Icofont icon="list" /> {order.lineitems.length} {_.upperCase(transMsg("items"))}
              </small>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              {order.lineitems.map((v, i) => {
                return (
                  <React.Fragment key={i}>
                    <CartDropdownItem image={v.item?.photo?.url} choices={v.choices} note={v.note} title={`${v.title} x ${v.quantity}`} price={`${priceFormat(v.totalPrice)}`} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <hr />
          <div className="row py-3">
            <div className="col-12">
              <p className=" font-14 text-dark">
                {transMsg("itemTotal")} <span className="float-right text-dark">{priceFormat(order.subtotal)}</span>
              </p>
              {order.discountTotal > 0 && (
                <p className="mb-1 font-14 text-dark">
                  {transMsg("discount")}{" "}
                  <span className="float-right text-dark">
                    ({order?.offer?.code && order?.offer?.code}) - {priceFormat(order.discountTotal)}
                  </span>
                </p>
              )}
              {order.type === "delivery" && (
                <p className="mb-1">
                  {transMsg("delivery")} <span className="float-right text-dark">{priceFormat(order.deliveryFees)}</span>
                </p>
              )}
              {order.taxTotal > 0 && (
                <p className="">
                  {transMsg("tax")} <span className="float-right text-dark">{priceFormat(order.taxTotal)}</span>
                </p>
              )}
              {order.tip > 0 && (
                <p className="">
                  {transMsg("tip")} <span className="float-right text-dark">{priceFormat(order.tip)}</span>
                </p>
              )}

              <hr />
              <h6 className="font-weight-bold mb-0">
                {transMsg("total")} <span className="float-right">{priceFormat(order.total)}</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
